<template>
  <footer class="footer">
    <DefaultSection class="footer_section">
      <Container>
        <div class="columns">
          <div class="column is-one-quarter is-hidden-mobile">
            <br>
          </div>
        </div>
      </Container>
    </DefaultSection>

    <DefaultSection class="py-0 subfooter has-background-primary">
      <Container>
        <div class="columns my-0">
          <div class="column is-half social-icons" />
          <div class="column is-offset-1">
            <FooterSubMenu />
          </div>
        </div>
      </Container>
    </DefaultSection>
  </footer>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
footer {
}

.footer_section {
  padding-top: toRem(80);
  padding-bottom: toRem(80);

  .store-buttons {
    margin-top: toRem(20);

    svg {
      max-width: 150px;
    }

    @include tablet {
      margin-top: 0;
      max-width: unset;
      .firstbutton {
        margin-bottom: -1rem;
      }
    }
  }
}

.subfooter {
  .social-icons {
    @include mobile {
      display: flex;
      justify-content: space-between;
    }

    a:last-of-type {
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }
}
</style>
