<template>
  <section
    class="menu-container has-background-primary section"
    :class="{ open }"
  >
    <nav role="menu" aria-label="main navigation" class="navbar main-nav section is-primary is-size-5 is-size-3-touch has-text-weight-bold is-fixed-top">
      <div class="container">
        <div class="navbar-brand">
          <nuxt-link aria-label="Home" class="navbar-item py-0" to="/">
            <Logo class="image" />
          </nuxt-link>
          <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" :class="{ 'is-active': open }" @click="open = !open">
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': open }">
          <div class="navbar-start" />
          <div class="navbar-end" />
        </div>
      </div>
    </nav>
  </section>
</template>

<script setup lang="ts">
import Logo from '~/assets/svgs/lessmess-logo.svg?component'

const open = ref(false)
</script>
