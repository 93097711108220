<template>
  <div>
    <slot />
    <MenuMinimal />
    <FooterContentMinimalistic />
  </div>
</template>

<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'has-navbar-fixed-top',
  },
})
</script>

<style lang="scss">
html {
  font-size: 12px;

  @include widescreen {
    font-size: 15px;
  }
}
</style>
